<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.UserId"
        placeholder="UserId"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.Email"
        placeholder="Email"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.TokenCode"
        placeholder="TokenCode"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-select
        v-model="listQuery.JournalType"
        clearable
        placeholder="Journal Type"
        size="small"
        class="filter-item"
      >
        <el-option
          v-for="item in journalTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >Search</el-button
      >
      <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="handleExport"
          type="xls"
          name="BalanceJournal.xls"
        >
        <el-button
          type="primary"
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleFilter"
        >Export</el-button>
        </download-excel>
      </span>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="Created on" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.CreatedUtc | dateStr }}
        </template>
      </el-table-column>
      <el-table-column label="Email" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.User.Email }}
        </template>
      </el-table-column>
      <el-table-column label="Token Code" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.TokenCode }}
        </template>
      </el-table-column>
      <el-table-column label="Amount" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.Amount }}
        </template>
      </el-table-column>
      <el-table-column label="Fee" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.Fee }}
        </template>
      </el-table-column>
      <el-table-column label="Journal Type" min-width="110" align="center">
        <template slot-scope="scope">
          {{ getJournalTypeName(scope.row.ActionType) }}
        </template>
      </el-table-column>
      <el-table-column label="Status" min-width="110" align="center">
        <template slot-scope="scope">
          {{ getJournalStatusName(scope.row.Status) }}
        </template>
      </el-table-column>
      <el-table-column label="Reference Id" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.ReferenceId }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        UserId: '',
        Email: '',
        TokenCode: '',
        JournalType: '',
      },
      journalTypes: [
        {label: "DEPOSIT", value: 10},
        {label: "WITHDRAWAL", value: 20},
        {label: "TRADERPOT", value: 30},
        {label: "REFERRALPOT", value: 40},
        {label: "BDPOT", value: 50},
        {label: "CHALLENGEPOOL", value: 60},
        {label: "AMBASSADORUSDT", value: 70},
        {label: "AMBASSADORCLAIM", value: 80},
        {label: "BDCLAIM", value: 90},
        {label: "TRADERCLAIM", value: 100},
        {label: "CHALLENGECLAIM", value: 110},
        {label: "NO_REFERRAL_RECLAIM", value: 120},
        {label: "REFERRAL_NOTACTIVE_RECLAIM", value: 130},
        {label: "NO_AMBASSADOR_RECLAIM", value: 140},
        {label: "AMBASSADOR_TARGET_NOT_HIT_RECLAIM", value: 150},
        {label: "NO_BD_RECLAIM", value: 160},
        {label: "POT_FEE", value: 170},
      ],
      exportLoading: false,
      json_fields: {
        CreatedUtc: 'CreatedUtc',
        Email: 'User.Email',
        TokenCode: 'TokenCode',
        Amount: 'Amount',
        Fee: 'Fee',
        ActionType: {
          field: "ActionType",
          callback: (value) => {
            return this.getJournalTypeName(value);
          },
        },
        Status: {
          field: "Status",
          callback: (value) => {
            return this.getJournalStatusName(value);
          },
        },
        ReferenceId: 'ReferenceId',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/admin/report/balanceJournal",
        method: "get",
        params: this.listQuery
      }).then(response => {
        console.log(response.data);
        this.list = response.data.Data;
        this.total = response.data.Total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getJournalTypeName(val) {
      if (val == 10) return "DEPOSIT";
      if (val == 20) return "WITHDRAWAL";
      if (val == 30) return "TRADERPOT";
      if (val == 40) return "REFERRALPOT";
      if (val == 50) return "BDPOT";
      if (val == 60) return "CHALLENGEPOOL";
      if (val == 70) return "AMBASSADORUSDT";
      if (val == 80) return "AMBASSADORCLAIM";
      if (val == 90) return "BDCLAIM";
      if (val == 100) return "TRADERCLAIM";
      if (val == 110) return "CHALLENGECLAIM";
      if (val == 120) return "NO_REFERRAL_RECLAIM";
      if (val == 130) return "REFERRAL_NOTACTIVE_RECLAIM";
      if (val == 140) return "NO_AMBASSADOR_RECLAIM";
      if (val == 150) return "AMBASSADOR_TARGET_NOT_HIT_RECLAIM";
      if (val == 160) return "NO_BD_RECLAIM";
      if (val == 170) return "POT_FEE";
    },
    getJournalStatusName(val) {
      if (val == 10) return "SUCCESS";
      if (val == 20) return "PENDING";
      if (val == 30) return "LOCKING";
      if (val == 40) return "REJECT";
      if (val == 50) return "CANCEL";
    },
    handleExport() {
      this.exportLoading = true;
      var query = JSON.parse(JSON.stringify(this.listQuery));
      query.limit = 99999999999999;
      return request({
        url: "/api/admin/report/balanceJournal",
        method: "get",
        params: query,
      })
      .then((result) => {
        var json_data = result.data.Data;
        return json_data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.exportLoading = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
